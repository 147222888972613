<template>
  <RoleForm mode="Tambah" module="Role"> </RoleForm>
</template>

<script>
import RoleForm from './form';

const RoleAdd = {
  name: 'RoleAdd',
  components: { RoleForm },
};

export default RoleAdd;
</script>
